import React, { useContext } from "react"

import tw from "tailwind-styled-components"

import ImgIndonesiaFlag from "components/images/flag-indonesia.inline.svg"
import ImgUnionFlag from "components/images/flag-union.inline.svg"
import LanguageContext from "components/ctx/language-context"

const LanguageSelector = () => {
  const langCtx = useContext(LanguageContext)
  return (
    <div className="inline-grid grid-cols-2 gap-2 max-w-full">
      <button
        type="button"
        onClick={() => langCtx.toggleLanguage("id")}
        aria-label="Bahasa Indonesia"
      >
        <IndonesiaFlag $curLang={langCtx.language} />
      </button>
      <button
        type="button"
        onClick={() => langCtx.toggleLanguage("en")}
        aria-label="English"
      >
        <UnionFlag $curLang={langCtx.language} />
      </button>
    </div>
  )
}

const IndonesiaFlag = tw(ImgIndonesiaFlag)`
    w-10
    max-w-full
    rounded-full
    ${e => (e.$curLang === "id" ? "" : "opacity-20 filter grayscale")}
    hover:opacity-100
    hover:grayscale-0
    hover:filter-none
`
const UnionFlag = tw(ImgUnionFlag)`
    w-10
    max-w-full
    rounded-full
    ${e => (e.$curLang === "en" ? "" : "opacity-20 filter grayscale")}
    hover:opacity-100
    hover:grayscale-0
    hover:filter-none
`

export default LanguageSelector
