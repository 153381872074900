import React, { useContext } from "react"

import ImgLogoKementrianInvestasiId from 'components/images/logo-kementrian-investasi-2.inline.svg'
import ImgLogoKementrianInvestasiEn from 'components/images/logo-kementrian-investasi-en.inline.svg'
import ArrowButton from 'components/UI/ArrowButton'
import LanguageContext from "components/ctx/language-context"

const LogoKementrian = (props) => {
    const langCtx = useContext(LanguageContext)
    return (
        <div { ...props }>
            <a href="https://www.bkpm.go.id/" target="_blank" className="w-full block" rel="noreferrer">
                {
                    langCtx.language == "id" ? <ImgLogoKementrianInvestasiId className="w-full" alt="Ministry of Investment / Badan Koordinasi Penanaman Modal"/>
                    : 
                    <ImgLogoKementrianInvestasiEn className="w-full" />
                }
                
            </a>
        </div>
    )
}

export default LogoKementrian
