export default {
    card: {
        base: 'min-w-0 rounded-lg overflow-hidden'
    },
    cardBody: {
        base: 'p-2',
    },
    button: {
        primary: {
            base: 'text-white bg-bkpm-light-blue-01 border border-transparent',
            active: 'active:bg-light-blue-01 hover:bg-light-blue-01 focus:ring focus:ring-light-blue-02',
            disabled: 'opacity-50 cursor-not-allowed',
        },
    }
  }