import React, { useContext } from "react"
import { Link } from "gatsby"

import ImgLogo from "components/images/logo.inline.svg"
import LanguageContext from "components/ctx/language-context"
import ImgLogoId from "components/images/logo/Logo_PIR_Tagline_V3_IND.png"
import ImgLogoEn from "components/images/logo/Logo_PIR_Tagline_V3_Eng.png"

const LogoContainer = props => {
  const langCtx = useContext(LanguageContext)
  return (
    <div {...props}>
      {/* <Link to="/" ><ImgLogo alt="Potensi Investasi Regional" /></Link> */}
      <Link to="/">
        {langCtx.language == "id" ? (
          <img src={ImgLogoId} style={{maxHeight:60}} alt="Potensi Investasi Regional" />
        ) : (
          <img src={ImgLogoEn} style={{maxHeight:60}} alt="Potential Investment Regional" />
        )}
      </Link>
    </div>
  )
}

export default LogoContainer
