import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import ResponsiveContainer from "components/UI/ResponsiveContainer"

import Logo from "./Logo"
import LogoKementrian from "./LogoKementrian"
import MobileMenuToggleBtn from "./MobileMenuToggleBtn"
import MainMenu from "./Menu/MainMenu"
import MobileMenu from "./Menu/MobileMenu"
import LanguageSelector from "./Menu/LanguageSelector"
import axios from "axios"
import LanguageContext from "components/ctx/language-context"
import { graphql, useStaticQuery } from "gatsby"

const Header = props => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false)

  const toggleBtnHandler = () => {
    setToggleMobileMenu(prevstate => !prevstate)
  }

  const langCtx = useContext(LanguageContext)
  const [content, setContent] = useState(null)
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiUrl
          }
        }
      }
    `
  )
  useEffect(async () => {
    // console.log("req header api")
    const url = process.env.APIURL + "api/FrontEnd/GetMenuFooter"
    const result = await axios.get(url, {
      headers: {
        "X-localization": langCtx.language,
      },
    })

    setContent({ ...result.data.Datas })
  }, [langCtx])

  return (
    content !== null && (
      <>
        <MobileMenu content={content} mobileMenuState={toggleMobileMenu} />
        <header className="bg-white fixed inset-x-0 top-0 py-4 z-40 border-b border-solid border-gray-200 lg:py-2">
          <ResponsiveContainer>
            <div className="flex justify-between items-center">
              <Logo className="w-25 lg:w-21 xl:w-33" />
              <MainMenu
                content={content}
                className="hidden lg:block lg:mx-8"
                pageGroup={props.pageGroup}
              />
              <div className="hidden w-12 mr-8 lg:block xl:w-16">
                <LanguageSelector />
              </div>
              <LogoKementrian className="hidden lg:flex lg:flex-col lg:items-end lg:w-48 xl:w-60" />
              <MobileMenuToggleBtn
                className="lg:hidden"
                clickHandler={toggleBtnHandler}
                mobileMenuState={toggleMobileMenu}
              />
            </div>
          </ResponsiveContainer>
        </header>
      </>
    )
  )
}

Header.propTypes = {
  pageGroup: PropTypes.string,
}

export default Header
