import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Dropdown } from '@windmill/react-ui'
import PropTypes from 'prop-types'

let submenuTimeout = null

const MenuItem = (props) => {

    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
    const hasSubmenu = typeof props.children === 'undefined' ? false : true

    const submenuOpenHandler = () => {
        setIsSubmenuOpen(true);
        submenuTimeout = setTimeout(function() {
            setIsSubmenuOpen(false)
        }, 1000)
    }
    
    const menuClickHandler = (e) => {
        e.preventDefault()
        if ( !hasSubmenu ) {
            navigate( props.menuLink )
        } else {
            submenuOpenHandler()
        }

    }

    return (
        <Container className={ props.className }>
            <button onClick={ menuClickHandler } className="font-bold">{ props.menuLabel }</button>
            { hasSubmenu && 
                <Dropdown isOpen={isSubmenuOpen} onClose={() => {}} onMouseOver={() => clearTimeout(submenuTimeout) } onMouseLeave={submenuOpenHandler} >
                    { props.children }
                </Dropdown> 
            }
          </Container>
    )
}

MenuItem.propTypes = {
    menuLabel: PropTypes.string.isRequired,
    menuLink: PropTypes.string.isRequired,
}

const Container = styled.li`
  position: relative;
  font-weight: bold;
  color: rgb(var(--dark-blue-01));
  line-height: 2em;
  &:after {
    content: "";
    display: block;
    max-width: 0;
    height: 2px;
    background: rgb(var(--dark-blue-01));
    transition: max-width ease-in-out 300ms;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover,
  &.active {
    &:after {
      max-width: 100%;
    }
  }
`

export default MenuItem
