import React from 'react'
import PropTypes from 'prop-types'

import tw from 'tailwind-styled-components'

const MobileMenuToggleBtn = (props) => {
    const { clickHandler, mobileMenuState, ...containerProps } = props
    return (
        <div { ...containerProps }>
            <button className="text-bkpm-dark-blue-01 w-10 h-10 relative focus:outline-none bg-white" onClick={ props.clickHandler }>
                <div className="blockw-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <BurgerBar01 $mobileMenuState={ props.mobileMenuState } />
                    <BurgerBar02 $mobileMenuState={ props.mobileMenuState } />
                    <BurgerBar03 $mobileMenuState={ props.mobileMenuState } />
                </div>
            </button>
        </div>
    )
}

MobileMenuToggleBtn.propTypes = {
    clickHandler: PropTypes.func,
    mobileMenuState: PropTypes.bool
}

const BurgerBar01 = tw.span`
    block
    absolute
    h-0.5
    w-5
    bg-bkpm-dark-blue-01
    transform
    transition
    duration-500
    ease-in-out
    ${ (e) => e.$mobileMenuState ? 'rotate-45' : '-translate-y-1.5' }
`
const BurgerBar02 = tw.span`
    block
    absolute
    h-0.5
    w-5
    bg-bkpm-dark-blue-01
    transform
    transition
    duration-500
    ease-in-out
    ${ (e) => e.$mobileMenuState ? 'opacity-0': '' }
`
const BurgerBar03 = tw.span`
block
absolute
h-0.5
w-5
bg-bkpm-dark-blue-01
transform
transition
duration-500
ease-in-out
${ (e) => e.$mobileMenuState ? '-rotate-45' : 'translate-y-1.5' }
`
export default MobileMenuToggleBtn;