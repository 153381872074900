import React, { Fragment } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import MenuItem from "./MenuItem"

const MainMenu = props => {
  const { pageGroup, ...containerProps } = props

  return (
    <div {...containerProps}>
      <ul className="inline-grid grid-flow-col gap-6 lg:text-xs xl:text-sm" style={{fontSize:"0.9rem"}}>
        {props.content.menu.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.childrens.length !== 0 ? (
                <MenuItem
                  className={`${
                    props.pageGroup === item.pageGroup ? "active" : ""
                  }`}
                  menuLabel={item.menuLabel}
                  menuLink={item.menuLink}
                >
                  <ul>
                    {item.childrens.map((itemSubmenu, indexSubmenu) => {
                      return (
                        <li key={indexSubmenu}>
                          <Link
                            state={
                              itemSubmenu.state != undefined
                                ? itemSubmenu.state
                                : ""
                            }
                            to={itemSubmenu.menuLink}
                          >
                            {itemSubmenu.menuLabel}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </MenuItem>
              ) : (
                <MenuItem
                  className={`${
                    props.pageGroup === item.pageGroup ? "active" : ""
                  }`}
                  menuLabel={item.menuLabel}
                  menuLink={item.menuLink}
                />
              )}
            </Fragment>
          )
        })}
        {/* <MenuItem className={ `${props.pageGroup === 'daerah' ? 'active' : ''}` } menuLabel="Daerah" menuLink="/peta-realisasi-investasi" />
          <MenuItem className={ `${props.pageGroup === 'peluang-investasi' ? 'active' : ''}` } menuLabel="Peluang Investasi" menuLink="/peluang-investasi" />
          <MenuItem className={ `${props.pageGroup === 'infrastruktur' ? 'active' : ''}` } menuLabel="Infrastruktur" menuLink="#">
            <ul>
              <li><Link to="/infrastruktur">Infrastruktur</Link></li>
              <li><Link to="/kawasan-industri-kek">Kawasan Industri &amp; KEK</Link></li>
            </ul>
          </MenuItem>
          <MenuItem className={ `${props.pageGroup === 'insentif' ? 'active' : ''}` } menuLabel="Insentif" menuLink="#">
            <ul>
              <li><Link to="/insentif" state={{ defaultTab: 'taxholiday'}}>Tax Holiday</Link></li>
              <li><Link to="/insentif" state={{ defaultTab: 'taxallowance'}}>Tax Allowance</Link></li>
              <li><Link to="/insentif" state={{ defaultTab: 'masterlist'}}>Master List</Link></li>
              <li><Link to="/insentif" state={{ defaultTab: 'superdeduction'}}>Super Deduction</Link></li>
            </ul>
          </MenuItem>
          <MenuItem className={ `${props.pageGroup === 'umkm' ? 'active' : ''}` } menuLabel="Kemitraan Usaha Nasional" menuLink="/daftar-umkm" /> */}
      </ul>
    </div>
  )
}

MainMenu.propTypes = {
  pageGroup: PropTypes.string,
}

export default MainMenu
