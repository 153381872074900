import React, { useContext } from "react"
import tw from "tailwind-styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import ImgLogoKementrianInvestasi from "components/images/logo-kementrian-investasi.inline.svg"
import ArrowButton from "components/UI/ArrowButton"
import LanguageSelector from "../LanguageSelector"
import Section from "./Section"
import SectionTitle from "./SectionTitle"

import LanguageContext from "components/ctx/language-context"
import ImgLogoId from "components/images/logo/Logo_PIR_Tagline_V3_IND.png"
import ImgLogoEn from "components/images/logo/Logo_PIR_Tagline_V3_Eng.png"

const Container = tw.div`
    fixed
    inset-0
    bg-white
    z-40 transform
    transition-all
    duration-300
    flex
    flex-col
    justify-center
    items-center
    pt-24
    pb-8
    ${e => (e.$mobileMenuState ? "" : "-translate-y-full")}
`

const MobileMenu = props => {
  const { pageGroup, ...containerProps } = props
  const langCtx = useContext(LanguageContext)
  return (
    <Container $mobileMenuState={props.mobileMenuState}>
      <div className="overflow-y-scroll h-full w-full text-center">
        {props.content.menu.map((item, index) => {
          return (
            <Section key={index}>
              <Link to={item.menuLink}>
                <SectionTitle item={item}>{item.menuLabel}</SectionTitle>
              </Link>
              <div className={`${item && item.childrens && item.childrens.length > 0 ? "gap-4" : ""} grid grid-flow-row`}>
              {item.childrens && item.childrens.map((itemSubmenu, indexSubmenu) => {
                  return (
                    <Link key={indexSubmenu} to={itemSubmenu.menuLink} state={{defaultTab :
                      itemSubmenu.state != undefined
                        ? itemSubmenu.state
                        : ""
                      }}>
                      {itemSubmenu.menuLabel}
                    </Link>)
                })
              }
              </div>
            </Section>)
          })
        }
        <Section key={9999}>
          <SectionTitle>{langCtx.language == "id" ? "Pilih Bahasa" : "Select Language"}</SectionTitle>
          <LanguageSelector />
        </Section>
        {/* <Section>
          <SectionTitle>Peluang</SectionTitle>
          <div className="grid grid-flow-row gap-4">
            <Link to="/peta-realisasi-investasi">Daerah</Link>
            <Link to="/peluang-investasi">Peluang Investasi</Link>
          </div>
        </Section>
        <Section>
          <SectionTitle>Infrastruktur</SectionTitle>
          <div className="grid grid-flow-row gap-4">
            <Link to="/infrastruktur">Infrastruktur</Link>
            <Link to="/kawasan-industri-kek">Kawasan Industri &amp; KEK</Link>
          </div>
        </Section>
        <Section>
          <SectionTitle>Insentif</SectionTitle>
          <div className="grid grid-flow-row gap-4">
            <Link to="/insentif" state={{ defaultTab: "taxholiday" }}>
              Tax Holiday
            </Link>
            <Link to="/insentif" state={{ defaultTab: "taxallowance" }}>
              Tax Allowance
            </Link>
            <Link to="/insentif" state={{ defaultTab: "masterlist" }}>
              Master List
            </Link>
            <Link to="/insentif" state={{ defaultTab: "superdeduction" }}>
              Super Deduction
            </Link>
          </div>
        </Section>
        <Section>
          <SectionTitle>Berita Terkini</SectionTitle>
          <div className="grid grid-flow-row gap-4">
            <Link to="/">Daftar Berita Terkini</Link>
          </div>
        </Section>
        <Section>
          <SectionTitle>Pilih Bahasa</SectionTitle>
          <LanguageSelector />
        </Section> */}
        {/* <a href="https://www.bkpm.go.id/" target="_blank" rel="noreferrer">
          <ImgLogoKementrianInvestasi
            className="w-60 mx-auto"
            alt="Ministry of Investment / Badan Kordinasi Penanaman Modal"
          />
        </a> */}

        <a href="https://www.bkpm.go.id/" className="mb-2" target="_blank" rel="noreferrer">
        {langCtx.language == "id" ? (
          <img className="w-60 mx-auto" src={ImgLogoId} style={{maxHeight:60}} alt="Potensi Investasi Regional" />
        ) : (
          <img className="w-60 mx-auto" src={ImgLogoEn} style={{maxHeight:60}} alt="Potential Investment Regional" />
        )}
        </a>
        <ArrowButton buttonClick={() => false} buttonStyle="expanded">
          Login Admin
        </ArrowButton>
      </div>
    </Container>
  )
}

MobileMenu.propTypes = {
  mobileMenuState: PropTypes.bool,
}

export default MobileMenu
