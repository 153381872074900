import React from "react"
import PropTypes from "prop-types"
import "styles/global.css"
import { Windmill } from "@windmill/react-ui"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Header from "./Header"
import { LanguageContextProvider } from "components/ctx/language-context"
import Theme from "components/theme.js"
import { withPrefix } from "gatsby"

const Layout = props => {
  const pageTitle = `BKPM PIR${props.pageTitle ? ` - ${props.pageTitle}` : ""}`

  return (
    <Windmill theme={Theme}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link rel="manifest" href={withPrefix("/images/site.webmanifest")} />
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.6/esri/themes/light/main.css"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        {/* <script src="https://js.arcgis.com/4.21/"></script> */}
      </Helmet>
      <LanguageContextProvider>
        <Header pageGroup={props.pageGroup} />
        <Main id={props.pageID}>{props.children}</Main>
      </LanguageContextProvider>
    </Windmill>
  )
}

Layout.propTypes = {
  pageTitle: PropTypes.string,
  pageID: PropTypes.string,
  pageGroup: PropTypes.string,
}

// Component styling
const Main = styled.main`
  margin-top: 75px;
  @media (min-width: 1024px) {
  }
`

export default Layout
